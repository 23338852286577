<template>
  <div>
    <h2>技术分析 🔥</h2>
    <a-divider/>
    <!--    <a-table></a-table>-->
    <div v-show="false">
      <div ref="heatmapRSIChartRef" class="echarts-style"></div>
      <div ref="heatmapRSI2ChartRef" class="echarts-style"></div>
    </div>
    <div>🏊🏊🏊待完善...</div>
  </div>
</template>

<script setup>
import * as echarts from 'echarts'
import {ref} from "@vue/reactivity";
import {onMounted, onUnmounted} from "vue";

const heatmapRSIChartRef = ref(null)
const heatmapRSI2ChartRef = ref(null)
let heatmapRSIChart = null
let heatmapRSI2Chart = null
const rsiColors = {
  StrongSell: 'rgba(64, 183, 162,1)',
  Sell: 'rgba(64, 183, 162,0.6)',
  Neutral: 'rgba(169, 169, 169,1)',
  Buy: 'rgba(244, 84, 96,0.6)',
  StrongBuy: 'rgba(244, 84, 96,1)',
}

const initChart = function () {
  heatmapRSIChart = echarts.init(heatmapRSIChartRef.value);
  heatmapRSI2Chart = echarts.init(heatmapRSI2ChartRef.value);

  let chartData = [[0, 0, 5], [0, 1, 1], [0, 2, 0], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2], [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3], [0, 22, 2], [0, 23, 5], [1, 0, 7], [1, 1, 0], [1, 2, 0], [1, 3, 0], [1, 4, 0], [1, 5, 0], [1, 6, 0], [1, 7, 0], [1, 8, 0], [1, 9, 0], [1, 10, 5], [1, 11, 2], [1, 12, 2], [1, 13, 6], [1, 14, 9], [1, 15, 11], [1, 16, 6], [1, 17, 7], [1, 18, 8], [1, 19, 12], [1, 20, 5], [1, 21, 5], [1, 22, 7], [1, 23, 2], [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0], [2, 10, 3], [2, 11, 2], [2, 12, 1], [2, 13, 9], [2, 14, 8], [2, 15, 10], [2, 16, 6], [2, 17, 5], [2, 18, 5], [2, 19, 5], [2, 20, 7], [2, 21, 4], [2, 22, 2], [2, 23, 4], [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 0], [3, 8, 1], [3, 9, 0], [3, 10, 5], [3, 11, 4], [3, 12, 7], [3, 13, 14], [3, 14, 13], [3, 15, 12], [3, 16, 9], [3, 17, 5], [3, 18, 5], [3, 19, 10], [3, 20, 6], [3, 21, 4], [3, 22, 4], [3, 23, 1], [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 0], [4, 8, 0], [4, 9, 2], [4, 10, 4], [4, 11, 4], [4, 12, 2], [4, 13, 4], [4, 14, 4], [4, 15, 14], [4, 16, 12], [4, 17, 1], [4, 18, 8], [4, 19, 5], [4, 20, 3], [4, 21, 7], [4, 22, 3], [4, 23, 0], [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [5, 9, 0], [5, 10, 4], [5, 11, 1], [5, 12, 5], [5, 13, 10], [5, 14, 5], [5, 15, 7], [5, 16, 11], [5, 17, 6], [5, 18, 0], [5, 19, 5], [5, 20, 3], [5, 21, 4], [5, 22, 2], [5, 23, 0], [6, 0, 1], [6, 1, 0], [6, 2, 0], [6, 3, 0], [6, 4, 0], [6, 5, 0], [6, 6, 0], [6, 7, 0], [6, 8, 0], [6, 9, 0], [6, 10, 1], [6, 11, 0], [6, 12, 2], [6, 13, 1], [6, 14, 3], [6, 15, 4], [6, 16, 0], [6, 17, 0], [6, 18, 0], [6, 19, 0], [6, 20, 1], [6, 21, 2], [6, 22, 2], [6, 23, 6]]
    .map(function (item) {
      return [item[1], item[0], item[2] || '-'];
    })

  let options = {
    tooltip: {
      position: 'top'
    },
    grid: {
      height: '50%',
      top: '10%'
    },
    xAxis: {
      type: 'category',
      data: [
        '12a', '1a', '2a', '3a', '4a', '5a', '6a',
        '7a', '8a', '9a', '10a', '11a',
        '12p', '1p', '2p', '3p', '4p', '5p',
        '6p', '7p', '8p', '9p', '10p', '11p'
      ],
      splitArea: {
        show: true
      }
    },
    yAxis: {
      type: 'category',
      data: [
        'Saturday', 'Friday', 'Thursday',
        'Wednesday', 'Tuesday', 'Monday', 'Sunday'
      ],
      splitArea: {
        show: true
      }
    },
    visualMap: {
      min: 0,
      max: 10,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '15%'
    },
    series: [
      {
        name: 'Punch Card',
        type: 'heatmap',
        data: chartData,
        label: {
          show: true
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
  heatmapRSIChart.setOption(options)

  let scatterList = []
  for (let i = 0; i < 100; i++) {
    scatterList.push(
      [i + 1, Math.random() * 100]
    )
  }
  heatmapRSI2Chart.setOption({
      tooltip: {},
      xAxis: {},
      yAxis: [{
        max: 100,
        min: 0,
        interval: 20,
        // axisLabel: {
        //   formatter: function (value, index) {
        //     // 这里根据index（刻度的序号）来设置文字解释
        //     let labels = ['0-20', '20-40', '40-60', '60-80', '80-100'];
        //     return labels[index];
        //   }
        // }
      },
        // {
        //   type: 'value',
        //   max: 100,
        //   min: 0,
        //   interval: 5,
        //   axisLabel: {
        //     color: 'red', // 第二条Y轴的标签颜色
        //     inside: true, // 将刻度标签放在轴线内部
        //     // formatter: '{value}\nLabel 2' // 自定义刻度标签内容，这里加上了 "Label 2"
        //     z: 1000
        //   }, axisTick: {
        //     show: false // 不显示刻度
        //   },
        //   axisLine: {
        //     show: false // 不显示轴线
        //   },
        //   splitLine: {
        //     show: false // 不显示分隔线
        //   }
        // }
      ],
      series: [
        {
          symbolSize: 14,
          data: scatterList,
          type: 'scatter',
          label: {
            show: true,
            position: 'top',
            formatter: (params) => params.value[0]
          },
          itemStyle: {
            color: function (param) {
              // 这里根据数据中的第三个元素（size）来设置颜色
              let value = param.data[1];
              // 根据size的大小设置不同的颜色
              if (value < 20) {
                return rsiColors.StrongSell; // 红色，透明度为0.5
              } else if (value < 40) {
                return rsiColors.Sell; // 绿色，透明度为0.5
              } else if (value < 60) {
                return rsiColors.Neutral; // 绿色，透明度为0.5
              } else if (value < 80) {
                return rsiColors.Buy; // 绿色，透明度为0.5
              } else {
                return rsiColors.StrongBuy; // 蓝色，透明度为0.5
              }
            }
          },
          markArea: { //背景颜色
            silent: true,
            itemStyle: {
              borderWidth: 0.1,
              borderColor: 'rgba(0,0,0,0.5)'
            },
            label: {
              show: true, // 显示文字标签
              position: 'right', // 标签位置，可以根据需要调整
              formatter: function (param) {
                // 这里根据数据中的第三个元素（size）来设置颜色
                let value = param.data[1];
                // 根据size的大小设置不同的颜色
                if (value < 20) {
                  return '超卖'; // 红色，透明度为0.5
                } else if (value < 40) {
                  return '卖'; // 绿色，透明度为0.5
                } else if (value < 60) {
                  return '中性'; // 绿色，透明度为0.5
                } else if (value < 80) {
                  return '买'; // 绿色，透明度为0.5
                } else {
                  return '超买'; // 蓝色，透明度为0.5
                }
              }, // 文字内容，可以根据需要自定义
              color: 'gray', // 文字颜色
              fontSize: 16, // 文字大小
            },
            data: [
              [
                {
                  xAxis: 0,
                  yAxis: 0,
                  itemStyle: {
                    color: 'rgba(186, 229, 221,1)'
                  }
                },
                {
                  xAxis: 100,
                  yAxis: 20,
                }
              ],
              [
                {
                  xAxis: 0,
                  yAxis: 20,
                  itemStyle: {
                    color: 'rgba(227, 244, 242,1)'
                  }
                },
                {
                  xAxis: 100,
                  yAxis: 40,
                }
              ],
              [
                {
                  xAxis: 0,
                  yAxis: 40,
                  itemStyle: {
                    color: 'rgba(255, 255, 255,1)'
                  }
                },
                {
                  xAxis: 100,
                  yAxis: 60,
                }
              ],
              [
                {
                  xAxis: 0,
                  yAxis: 60,
                  itemStyle: {
                    color: 'rgba(253, 230, 232,1)'
                  }
                },
                {
                  xAxis: 100,
                  yAxis: 80,
                }
              ],
              [
                {
                  xAxis: 0,
                  yAxis: 80,
                  itemStyle: {
                    color: 'rgba(252, 205, 208,1)'
                  }
                },
                {
                  xAxis: 100,
                  yAxis: 100,
                }
              ]
            ]
          },
          markLine: {
            symbol: 'none', // 可以设置为 'none' 隐藏标记图标
            lineStyle: {
              type: 'dashed', // 使用虚线样式
              color: 'rgb(234, 152, 64)' // 虚线颜色
            },
            data: [
              {
                yAxis: 50, // 水平虚线的位置
                label: {
                  show: true,
                  position: 'insideEndTop',
                  formatter: 'Avg: 15.21', // 文字标签内容
                  fontSize: 18,
                  fontWeight: 500,
                  color: 'rgb(234, 152, 64)' // 虚线颜色

                }
              }
            ]
          }

        }
      ],
      // graphic: [
      //   {
      //     type: 'text',
      //     left: 80, // 控制文字标签的横向位置，根据需要设置
      //     top: 40, // 控制文字标签的纵向位置，根据需要设置
      //     z: 100,
      //     style: {
      //       text: '自定义标签', // 这里设置文字内容
      //       fill: 'black', // 文字颜色
      //       fontSize: 12 // 文字大小
      //     }
      //   }
      // ]
    }
  )
}

onMounted(() => {
  initChart()
})

onUnmounted(() => {
  echarts.dispose;
});

</script>

<style scoped>
  .echarts-style {
    min-height: 500px;
    width: 100% !important;
  }
</style>
